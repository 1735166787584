<template>
  <div class="custom--agreement-booking-detail">
    <div class="title--text">
      <span v-if="customAgreementData.issuer === 'APPLICANT'">{{
        $t('booking.modal.customAgreement.applicant')
      }}</span>
      <span v-if="customAgreementData.issuer === 'OWNER'">{{
        $t('booking.modal.customAgreement.owner')
      }}</span>
      {{ $t('booking.modal.customAgreement.title') }}
    </div>
    <div class="desc">
      {{ $t('booking.modal.customAgreement.message') }}
    </div>
    <div class="desc" v-if="customAgreementData.agreed_at || customAgreementData.rejected_at">
      <span v-if="customAgreementData.agreed_at">
        {{ $t('booking.modal.customAgreement.acceptDate') }}:
        {{ $date.format(customAgreementData.agreed_at, 'dd MMMM yyyy') }}
      </span>
      <span v-if="customAgreementData.rejected_at">
        {{ $t('booking.modal.customAgreement.rejectDate') }}:
        {{ $date.format(customAgreementData.rejected_at, 'dd MMMM yyyy') }}
      </span>
    </div>
    <div>
      <button class="btn btn--green" @click="seeAgreement">
        {{ $t('booking.modal.customAgreement.seeAgreement') }}
      </button>
      <button
        v-for="(action, index) in customAgreementData.allowed_actions"
        :key="`custom-agreeement-action-${index}`"
        type="button"
        class="btn btn-primary px-4 py-2 mt-1"
        @click="onClickAction(action)"
      >
        {{ $t('booking.modal.customAgreement.' + action) }}
      </button>
    </div>
    <accept-agreement-modal />
    <reject-agreement-modal />
  </div>
</template>

<script>
import { mapState } from 'vuex';

const AcceptAgreementModal = () => import('@/components/booking/modals/modal-accept-agreement');
const RejectAgreementModal = () => import('@/components/booking/modals/modal-reject-agreement');

export default {
  name: 'custom-agreement-section',
  components: {
    AcceptAgreementModal,
    RejectAgreementModal,
  },
  computed: {
    ...mapState({
      customAgreementData: state => state.v2.booking.detail.customAgreementData,
    }),
  },
  methods: {
    seeAgreement() {
      if (this.customAgreementData) {
        window.open(this.customAgreementData.agreement_url, '_blank');
      }
    },
    onClickAction(action) {
      if (action === 'ACCEPT') {
        this.$modal.show('modal-accept--agreement');
      } else if (action === 'REJECT') {
        this.$modal.show('modal-reject--agreement');
      }
    },
  },
};
</script>

<style scoped></style>
